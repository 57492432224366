<template>
  <main v-editable="props.blok" class="page" :data-uid="props.blok._uid">
    <StoryblokComponent
      v-for="contentBlok in props.blok.ContentBlocks"
      :id="contentBlok._uid"
      :key="contentBlok._uid"
      :blok="contentBlok"
    />
  </main>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true
  },
  urlPath: {
    type: String,
    required: false,
    default: ''
  }
})

const getMetaRobotContent = (robots) => {
  if (typeof robots !== 'object') {
    return { all: true }
  }
  return robots.reduce((resultObject, currentValue) => {
    resultObject[currentValue] = true
    return resultObject
  }, {})
}

if (props.blok.seo) {
  const serviceUrl = useRuntimeConfig().public.serviceUrl
  const headTitle = props.blok.seo.title ? props.blok.seo.title : props.blok.title
  // possible fields: https://github.com/harlan-zw/zhead/blob/main/src/metaFlat.ts
  useSeoMeta({
    title: headTitle
  })
  useServerSeoMeta({
    applicationName: headTitle,
    description: props.blok.seo.description,
    robots: getMetaRobotContent(props.blok.seoRobots),
    ogType: 'website',
    ogUrl: `${serviceUrl}${props.urlPath}`,
    ogTitle: props.blok.seo.og_title === '' ? headTitle : props.blok.seo.og_title,
    ogDescription:
      props.blok.seo.og_description === ''
        ? props.blok.seo.description
        : props.blok.seo.og_description,
    ogImage: props.blok.seo.og_image,
    twitterCard: 'summary_large_image',
    twitterSite: '@pflegede',
    twitterTitle: props.blok.seo.twitter_title === '' ? headTitle : props.blok.seo.twitter_title,
    twitterDescription:
      props.blok.seo.twitter_description === ''
        ? props.blok.seo.description
        : props.blok.seo.twitter_description,
    twitterImage: props.blok.seo.twitter_image
  })
  useHead({
    style: [{ textContent: props.blok.css }]
  })
}

const tracking = useTracking()
const publicConfig = useRuntimeConfig().public
const productName = props.urlPath.split('/')[0]

onBeforeMount(() => {
  tracking.init(window, document, publicConfig.googleTagmanagerKey, {
    pageTitle: props.blok.seo?.title ? props.blok.seo.title : props.blok.title
  })
  tracking.trackDatalayer({ event: 'page_view', label: props.urlPath })
  tracking.initET(productName)
})
</script>
